import React, { Component } from "react";
import DashboardLayout from "../layouts/Dashboard";

import { useKeycloak } from "@react-keycloak/web";

import { useSelector, useDispatch } from "react-redux";
import * as actionCaller from "../actions";
import * as serviceCaller from "../service";

export default ({ route }) => {
  const [keycloak, initialized] = useKeycloak();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.session);

  // let location = useLocation();
  // const isLoggedIn = useSelector((state) => state.Auth.idToken);
  // const isAuthId = localStorage.getItem("id_token");

  console.log("initialized : ",initialized);
  console.log("keycloak : ",keycloak);
  if (initialized && keycloak.authenticated) {
    if (reduxState.user && !reduxState.user._id) {
      keycloak.loadUserInfo().then(async(res) => {
        await serviceCaller.getUserData(res.sub, (val) =>{
          console.log("res : ",res);
          dispatch(actionCaller.login(val))
        }
        );
      });
    }

    const isAllowed = (realmRoles, pageRoles) => {
      let matchingRoles = pageRoles.filter(function (e) {
        return realmRoles.indexOf(e) > -1;
      });
      return matchingRoles.length >= 1 ? true : false;
    };

    let authorizedRoutes = route.routes.filter((e) =>
      isAllowed(keycloak.realmAccess.roles, e.access)
    );

    route.routes = authorizedRoutes;

    // serviceCaller.getUserData()
    //(val) => dispatch(actionCaller.login(val))
    return <DashboardLayout route={route} />;
  } else {
    keycloak.login();
  }
};

// class CheckAuth extends Component {
//   render() {

//     const { route } = this.props;
//     return <DashboardLayout route={route} />;
//   }
// }

// export default CheckAuth;
