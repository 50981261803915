import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";

class Preview extends Component {
  render() {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Typography variant="h4" align="center" 
        // color="secondary"
        >
          No Preview!
        </Typography>
      </Grid>
    );
  }
}

export default Preview;
