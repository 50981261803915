const axios = require("axios");
const keycloak = require("../keycloak");

// const baseURL = "https://dev-api-inv.thilash.com/api/v1/";
// const baseURL = "https://invtest.thefunnelguru.com/api/v1/";
// const baseURL = "http://localhost:3003/api/v1/";
const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;

//

export function addAutomotive(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURLV2}automotives`, data, { headers: headers });
}

export function getAutomotive(wid) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}automotives/${wid}`, { headers: headers });
}

export function addNewAutomotiveWebsite(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}automotive`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function updateAutomotiveWebsite(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURL}automotive/?id=${data._id}`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function getAllAutomotive(type = "all", callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}automotive?type=${type}`, {
      headers: headers,
    })
    .then((res) =>
      res.data.statusCode == 200 ? callback(res.data.data) : null
    )
    .catch(function (error) {});
}

export function getAllAutomotiveQuery(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}automotives`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}
