import React from "react";
import { Button } from "@mui/material";

// import { useSnackbar } from "notistack";
import { notification } from "antd";
import { useConfirm } from "material-ui-confirm";
import * as serviceCaller from "../../service";

function RollBackInvoiceItemAction({
  invoiceData,
  invoiceItemData,
  setInvoiceItemData,
}) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const confirm = useConfirm();

  return (
    <Button
      size="small"
      variant="outlined"
      // color="secondary"
      onClick={async () => {
        confirm({
          title: "Roll Back?",
          description:
            "Are you sure you want to roll back this case? This cannot be undone.",
          confirmationText: "Roll Back",
          cancellationText: "cancle",
          confirmationButtonProps: {
            variant: "contained",
            color: "primary",
            size: "small",
          },
          cancellationButtonProps: {
            size: "small",
          },
        }).then(() => {
          serviceCaller
            .updateInvoiceItemById(invoiceItemData._id, {
              action: "ROLLBACK",
            })
            .then((res) => {
              if (res.status === 200) {
                notification.success({
                  message:res.data.message,
                  placement:"bottomLeft",
                  duration:2.5
                })
                // enqueueSnackbar(res.data.message, {
                //   variant: "success",
                // });
                setInvoiceItemData({
                  ...invoiceItemData,
                  result: undefined,
                });
              } else {
                notification.error({
                  message:res.data.message,
                  placement:"bottomLeft",
                  duration:2.5
                })
                // enqueueSnackbar(res.data.message, {
                //   variant: "error",
                // });
              }
            });
        });
      }}
    >
      Roll Back
    </Button>
  );
}

export default RollBackInvoiceItemAction;
