import React, { useState, useEffect } from "react";
import { Badge, Button, CircularProgress, Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as serviceCaller from "../../service";

function ViewDocsButton({ rowData, fileIds = [], ...rest }) {
  let [loading, setLoading] = useState(false);

  return (
    <Badge 
    color="primary"
    //  color="secondary"
      badgeContent={fileIds.length}>
      {
        window?.location?.pathname?.includes("validate") ?
        <Button
        style={{
          color: fileIds.length  ? "#263238" : "",
          border: fileIds.length  ? "1px solid #263238" : "",

        }}
        variant="outlined"
        startIcon={
          loading ? (
            <CircularProgress size={18} />
          ) : (
            <Visibility fontSize="small" />
          )
        }
        onClick={async () => {
          setLoading(true);

          for (let file of fileIds) {
            let fileRes = await serviceCaller.getObject({
              fileId: file,
            });

            if (fileRes) {
              const fileArrayBuffer = new Uint8Array(
                fileRes.response.Body.data
              );
              const fileBlob = new Blob([fileArrayBuffer], {
                type: `${
                  fileRes.fileType == "pdf"
                    ? "application/pdf"
                    : "image/" + fileRes.fileType
                }`,
              });

              const filePreview = window.URL.createObjectURL(fileBlob);

              window.open(
                `${window.location.origin}/doc/${encodeURIComponent(
                  filePreview
                )}?wufoo=${rowData && rowData.wufoo}&invoiceNo=${
                  rowData &&
                  rowData.invoiceData &&
                  rowData.invoiceData.invoiceNo
                }&category=${""}&usedCount=${fileRes.usedCount}`
              );
            }
          }

          setLoading(false);
        }}
        disabled={loading || fileIds.length < 1}
        {...rest}
      >
        Docs
      </Button>
        :

        <Button
        variant="outlined"
        startIcon={
          loading ? (
            <CircularProgress size={18} />
          ) : (
            <Visibility fontSize="small" />
          )
        }
        onClick={async () => {
          setLoading(true);

          for (let file of fileIds) {
            let fileRes = await serviceCaller.getObject({
              fileId: file,
            });

            if (fileRes) {
              const fileArrayBuffer = new Uint8Array(
                fileRes.response.Body.data
              );
              const fileBlob = new Blob([fileArrayBuffer], {
                type: `${
                  fileRes.fileType == "pdf"
                    ? "application/pdf"
                    : "image/" + fileRes.fileType
                }`,
              });

              const filePreview = window.URL.createObjectURL(fileBlob);

              window.open(
                `${window.location.origin}/doc/${encodeURIComponent(
                  filePreview
                )}?wufoo=${rowData && rowData.wufoo}&invoiceNo=${
                  rowData &&
                  rowData.invoiceData &&
                  rowData.invoiceData.invoiceNo
                }&category=${""}&usedCount=${fileRes.usedCount}`
              );
            }
          }

          setLoading(false);
        }}
        disabled={loading || fileIds.length < 1}
        {...rest}
      >
        Docs
      </Button>
      }
    

    </Badge>
  );
}

export default ViewDocsButton;
