const axios = require("axios");
const keycloak = require("../keycloak");
const baseURL = process.env.REACT_APP_BASEURL;
const baseURL2 = process.env.REACT_APP_BASEURL_V2;

export function addMailsTosalesForce(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios
    .post(`${baseURL2}salesforce`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}
