import React from "react";
import { Button, Typography } from "@mui/material";

function LinkButton({ url, name, ...rest }) {
  return (
    <Button
      variant="outlined"
      onClick={() => {
        if (!/^https?:\/\//i.test(url)) {
          url = "http://" + url;
        }
        window.open(url, "_blank");
      }}
      {...rest}
    >
      <Typography>{name}</Typography>
    </Button>
  );
}

export default LinkButton;
