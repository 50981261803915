const axios = require("axios");
const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;

const { INVOICE_BOX, INVOICE_RANDOM } = require("../utils/values");

export function getInvoiceItems(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export async function getApplicationList({
  filter,
  datefilter,
  page,
  pageSize,
  sort = ["_id"]
}) {
  try {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.default.token,
    };
  
    console.log("FILTERS", filter);
    console.log("service caller getApplicationList sort", sort);
  
    let ApplicationData = await axios.get(`${baseURLV2}invoices/applications`, {
      headers: headers,
      params: {
        ...filter,
        page,
        pageSize,
        sort,
      },
    });
    console.log("ApplicationData : ", ApplicationData);
    return ApplicationData;
  } catch (error) {
    console.log({error});
  }
}

export function getInvoiceItemById(invoiceItemId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems/${invoiceItemId}`, {
    headers: headers,
  });
}

export function getInvoiceItemDocsById(invoiceItemId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems/${invoiceItemId}/docs`, {
    headers: headers,
  });
}

export function updateInvoiceItemById(invoiceItemId, invoiceData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.put(`${baseURLV2}invoiceitems/${invoiceItemId}`, invoiceData, {
    headers: headers,
  });
}

export function rejectCaseAppealEmail(value, invoiceData, invoiceItemData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURLV2}invoiceitems/appeal`, value, {
    headers: headers,
    params: {
      invoiceData: invoiceData,
      invoiceItemData: invoiceItemData,
    },
  });
}
export function getrejectCaseAppealEmail({ invoiceItemData }) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log("invoiceItemData : ", invoiceItemData);
  return axios.get(`${baseURLV2}invoiceitems/appeal/${invoiceItemData._id}`, {
    headers: headers,
  });
}

export function rejectCaseS2sEmail(value, rowData, invoiceItemData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURLV2}invoiceitems/s2s`, value, {
    headers: headers,
    params: {
      rowData: rowData,
      invoiceItemData: invoiceItemData,
    },
  });
}

export function getrejectCaseS2sEmail({ invoiceItemData }) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems/s2s/${invoiceItemData._id}`, {
    headers: headers,
  });
}
