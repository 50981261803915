import moment from "moment";

const axios = require("axios");
const keycloak = require("../keycloak");
const baseURL = process.env.REACT_APP_BASEURL;
const baseURL2 = process.env.REACT_APP_BASEURL_V2;

export function SendPermissionMail(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.post(`${baseURL2}customerpermission/send/permissionmail`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}
export function SendMasterPermissionMail(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.post(`${baseURL2}permission/send/master/permissionmail`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function SendInvitationAccessMail(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.post(`${baseURL2}customerpermission/send/invitationAccess`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function UpdateToComment(data , ApplicationTracking_Comment) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});

  let kc_id =  keycloak?.default?.idTokenParsed?.sub;

  return axios.put(`${baseURL}wufoo-api/updatedComment/${data}`,{
    ApplicationTracking_Comment:ApplicationTracking_Comment,
    created_on:moment().utc().valueOf(),
  },
  {
     headers: headers,
     params: {
      keycloak_id: keycloak?.default?.idTokenParsed?.sub,  
     },
  })
    .then((res) => res)
    .catch(function (error) {});
}
