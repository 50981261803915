import React from 'react';
import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
// import { useSnackbar } from "notistack";
import { notification } from 'antd';
import * as serviceCaller from "../../service";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ApproveInvoiceItemAction from "./ApproveInvoiceItemAction";
import RejectInvoiceItemAction from "./RejectInvoiceItemAction";
import AppealEmailSendAction from "./AppealEmailSendAction";
import NotUsedInvoiceItemAction from "./NotUsedInvoiceItemAction";
import { useForm, Controller } from "react-hook-form";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function InvoiceItemActions({
  invoiceData,
  invoiceItemData,
  setInvoiceItemData,
  customerData,
  update
}) {
  const [applicationDetailState, setApplicationDetailState] = useState(false);
  const [applicationDetailLoading, setApplicationDetailLoading] =
    useState(false);

  // const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      ...invoiceItemData,
      appliedOn: invoiceItemData.appliedOn || new Date().getTime(),
    },
  });
  const applicationDetail = async (data) => {
    // setApplicationDetailLoading(true);
    console.log("setApplicationDetailLoading", data);
    console.log("invoiceitem", invoiceItemData);
    await serviceCaller
      .updateInvoiceItemById(invoiceItemData._id, { ...data })
      .then((res) => {
        if (res.status === 200) {
          let { appliedStatus, appliedOn, caseLogNo, comment } =
            res.data.result;
          notification.success({
            message: res.data.message,
            placement: "bottomLeft",
            duration: 2.5
          })
          // enqueueSnackbar(res.data.message, {
          //   variant: "success",
          // });
          setInvoiceItemData({
            ...invoiceItemData,
            appliedStatus,
            appliedOn,
            caseLogNo,
            comment,
          });
          setApplicationDetailState(false);
        } else {
          notification.error({
            message: res.data.message,
            placement: "bottomLeft",
            duration: 2.5
          })
          // enqueueSnackbar(res.data.message, {
          //   variant: "error",
          // });
        }
      });
    setApplicationDetailLoading(false);
  };

  // console.log("data", data);
  // console.log("customerData: ", customerData);

  return (
    <>
      <Grid item>
        {invoiceItemData.appliedStatus == "applied" ? (
          <CheckCircleOutlineIcon style={{ color: "#005900" }} />
        ) : (
          <HighlightOffIcon style={{ color: "#990000" }} />
        )}
      </Grid>
      <Grid item>
        <ApproveInvoiceItemAction
          invoiceData={invoiceData}
          invoiceItemData={invoiceItemData}
          setInvoiceItemData={setInvoiceItemData}
        />
      </Grid>
      <Grid item>
        <RejectInvoiceItemAction
          invoiceData={invoiceData}
          invoiceItemData={invoiceItemData}
          setInvoiceItemData={setInvoiceItemData}
        />
      </Grid>
      <Grid item>
        <AppealEmailSendAction
          invoiceData={invoiceData}
          invoiceItemData={invoiceItemData}
          customerData={customerData}
          update={() => {
            console.log("LETS TFG : 6 ");
            update();
          }}
        />
      </Grid>
      <Grid item>
        <NotUsedInvoiceItemAction
          invoiceData={invoiceData}
          invoiceItemData={invoiceItemData}
          setInvoiceItemData={setInvoiceItemData}
        />
      </Grid>
      <Grid item>
        {/* {"Application Detail"} */}

        <IconButton
          size="small"
          onClick={() => setApplicationDetailState(true)}
        >
          <SettingsApplicationsIcon fontSize="small" />
        </IconButton>
      </Grid>

      {/* popup for application detail */}
      <Dialog
        onClose={() => setApplicationDetailState(false)}
        open={applicationDetailState}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{"Application Detail"}</DialogTitle>
        <form onSubmit={handleSubmit(applicationDetail)}>
          <DialogContent style={{ minHeight: "150px" }}>
            {applicationDetailLoading ? (
              <CircularProgress />
            ) : (
              <Grid alignItems="center" container justifyContent="center" spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        select
                        label="Application Status"
                        variant="outlined"
                        type="text"
                      >
                        <MenuItem value="notapplied">Not Applied</MenuItem>
                        <MenuItem value="applied">Applied</MenuItem>
                        <MenuItem value="update asked">Update Asked</MenuItem>
                      </TextField>
                    }
                    name="appliedStatus"
                    control={control}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    render={({ onChange, value }) => (

                      // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      //   <KeyboardDatePicker
                      //     margin="normal"
                      //     id="date-picker-dialog"
                      //     label="Applied On"
                      //     value={value}
                      //     onChange={onChange}
                      //     format="MM/dd/yyyy"
                      //     fullWidth
                      //     KeyboardButtonProps={{
                      //       "aria-label": "change date",
                      //     }}
                      //   />
                      // </MuiPickersUtilsProvider>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker

                          value={value}
                          onChange={onChange}
                          format="MM/dd/yyyy"
                          // fullWidth
                          // KeyboardButtonProps={{
                          //   "aria-label": "change date",
                          // }}
                          renderInput={(props) => (
                            <TextField {...props} label="Applied On" variant="standard" />
                          )}
                        />
                      </LocalizationProvider>

                    )}
                    name="appliedOn"
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Case Log No."
                        variant="outlined"
                        type="text"
                      />
                    }
                    name="caseLogNo"
                    control={control}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        rows="2"
                        type="text"
                        label="Comments"
                      />
                    }
                    name="comment"
                    control={control}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setApplicationDetailState(false)}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

    </>
  );
}

export default InvoiceItemActions;
