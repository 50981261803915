export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const GET_ACTIVE_SUPPLIERS = "GET_ACTIVE_SUPPLIERS";
export const REMOVE_ACTIVE_SUPPLIERS = "REMOVE_ACTIVE_SUPPLIERS";
export const GET_SUPPLIER_PRODUCTS = "GET_SUPPLIER_PRODUCTS";
export const SELECT_SUPPLIER = "SELECT_SUPPLIER";
export const SELECT_SUPPLIER_PRODUCT = "SELECT_SUPPLIER_PRODUCT";
export const GET_ALL_MARKETPLACES = "GET_ALL_MARKETPLACES";
export const SELECT_MARKETPLACE = "SELECT_MARKETPLACE";
export const GET_INVOICE_GENERATOR = "GET_INVOICE_GENERATOR";
export const GET_SUPPLIER_INVOICES = "GET_SUPPLIER_INVOICES";
export const GET_NEW_SUPPLIER_REFERENCE = "GET_NEW_SUPPLIER_REFERENCE";

export const newSupplierReference = (payload) => {
  return {
    type: GET_NEW_SUPPLIER_REFERENCE,
    payload: payload,
  };
};

export const getAllSuppliers = (payload) => {
  return {
    type: GET_ALL_SUPPLIERS,
    payload: payload,
  };
};

export const getActiveSuppliers = (payload) => {
  return {
    type: GET_ACTIVE_SUPPLIERS,
    payload: payload,
  };
};

export const removeActiveSuppliers = () => {
  return {
    type: REMOVE_ACTIVE_SUPPLIERS,
  };
};

export const getAllMarketPlaces = (payload) => {

  return {
    type: GET_ALL_MARKETPLACES,
    payload: payload,
  };
};

export const selectMarketPlace = (payload) => {
  return {
    type: SELECT_MARKETPLACE,
    payload: payload,
  };
};

export const getSupplierProducts = (payload) => {
  console.log("get supplier products payload",payload)
  return {
    type: GET_SUPPLIER_PRODUCTS,
    payload: payload,
  };
};

export const getSupplierInvoices = (payload) => {
  return {
    type: GET_SUPPLIER_INVOICES,
    payload: payload,
  };
};

export const getInvoiceGenerator = (payload) => {
  return {
    type: GET_INVOICE_GENERATOR,
    payload: payload,
  };
};

export const selectSupplier = (payload) => {
  console.log("selectSupplier payload",payload);
  return {
    type: SELECT_SUPPLIER,
    payload: payload,
  };
};

export const selectSupplierProduct = (payload) => {
  console.log("selectSupplierProduct payload",payload);
  return {
    type: SELECT_SUPPLIER_PRODUCT,
    payload: payload,
  };
};
