import React, { useState } from "react";
import {
  // Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import {
  Button,
  Tag,
  Tooltip,
  Modal,
  Input,
  Form,
  Col,
  Row,
  notification,
  message
} from 'antd';

import ViewDocsButton from "components/ViewDocsButton";
import AsinButton from "components/AsinButton";
import LinkButton from "components/LinkButton";
import AutomotiveButton from "components/AutomotiveButton";
import {
  PRIMARY,
  SECONDARY,
  INVOICE_AUTOMOTIVE,
  APPROVED,
} from "../../utils/values";
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";
import * as actionCaller from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import InvoiceItemActions from "./InvoiceItemActions";
import RollBackInvoiceItemAction from "./RollBackInvoiceItemAction";
import S2sSendEmailAction from "./S2sEmailSendAction";
import { useEffect } from "react";
import { async } from "validate.js";
const { TextArea } = Input;

function InvoiceItemSubRow({ rowData, data, isCaseLog = false, customerData, update, UpdateInvoiceItem }) {
  let [invoiceItemData, setInvoiceItemData] = useState(data);
  useEffect(() => {
    (async () => {
      console.log({ data });
      setInvoiceItemData(data);
    })();
  }, [UpdateInvoiceItem, data]);

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid item style={{ flex: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ minWidth: "100px" }}>
              <Typography
                component="h5"
                variant="button"
                color={
                  invoiceItemData.type == PRIMARY ? "primary" : "secondary"
                }
              >
                {String(invoiceItemData.type).toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ minWidth: "150px" }}>
              <Tooltip
                placement="topLeft"
                title={(invoiceItemData.masterproduct &&
                  invoiceItemData.masterproduct.category
                  ? invoiceItemData.masterproduct.category
                  : "").toUpperCase()}
              >
                <div style={{ whiteSpace: "nowrap", width: "130px", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {/* <Typography component="h5" variant="button"> */}
                  {(
                    invoiceItemData.masterproduct &&
                      invoiceItemData.masterproduct.category
                      ? invoiceItemData.masterproduct.category
                      : ""
                  ).toUpperCase()}
                  {/* </Typography> */}
                </div>
              </Tooltip>
              {/* <Typography component="h5" variant="button">
                {String(
                  invoiceItemData.masterproduct &&
                    invoiceItemData.masterproduct.category
                    ? invoiceItemData.masterproduct.category
                    : ""
                ).toUpperCase()}
              </Typography> */}
            </Grid>
            <Grid item xs={3}>
              {rowData.invoiceType != INVOICE_AUTOMOTIVE ? (
                <AsinButton asin={invoiceItemData.asin} size="small" />
              ) : (
                <AutomotiveButton
                  name="Website"
                  wid={invoiceItemData.wid}
                  size="small"
                />
              )}
            </Grid>
            <Grid item xs={3} style={{ minWidth: "150px" }}>
              <Typography component="h5" variant="button">
                {invoiceItemData.masterproduct?.brand && (
                  <Tag
                    color={"green"}
                    key={invoiceItemData.masterproduct?.brand}
                  >
                    <div>
                      <span style={{ textTransform: "capitalize" }}>
                        {invoiceItemData.masterproduct &&
                          invoiceItemData.masterproduct?.brand
                          ? invoiceItemData.masterproduct?.brand
                          : ""}
                      </span>
                    </div>
                  </Tag>
                )}
                {/* {String(
                  invoiceItemData.masterproduct &&
                    invoiceItemData.masterproduct?.brand
                    ? invoiceItemData.masterproduct?.brand
                    : ""
                ).toUpperCase()} */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Grid container spacing={2} alignItems="center" justify="flex-start">
            <Grid item>
              <ViewDocsButton
                rowData={rowData}
                fileIds={[
                  invoiceItemData.imageSet,
                  invoiceItemData.coaDoc,
                  invoiceItemData.fdaDoc,
                  invoiceItemData.otherDoc,
                ].filter((e) => e)}
                size="small"
              />
            </Grid>
            {isCaseLog ? (
              !invoiceItemData.result ? (
                <InvoiceItemActions
                  invoiceData={rowData}
                  invoiceItemData={invoiceItemData}
                  setInvoiceItemData={setInvoiceItemData}
                  customerData={customerData}
                  update={() => {
                    console.log("LETS TFG : 4 ");
                    update();
                  }}
                />
              ) : (
                <>
                  <Grid item>
                    <Typography
                      component="h5"
                      variant="button"
                      style={{
                        color:
                          invoiceItemData.result == APPROVED ? "green" : "red",
                      }}
                    >
                      {"Case Status - " + invoiceItemData.result}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RollBackInvoiceItemAction
                      invoiceData={rowData}
                      invoiceItemData={invoiceItemData}
                      setInvoiceItemData={setInvoiceItemData}
                    />
                  </Grid>
                  {invoiceItemData.result === "rejected" && (
                    <>
                      <Grid item>
                        <S2sSendEmailAction
                          invoiceData={rowData}
                          invoiceItemData={invoiceItemData}
                          customerData={customerData}
                          update={() => {
                            console.log("LETS TFG : 5 ");
                            update();
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {rowData.requestQuestion_sent ? (
                          <Button
                            onClick={async () => {
                              console.log({ rowData, data, isCaseLog, customerData, update, UpdateInvoiceItem })
                              let MailSent = await serviceCaller.rejectionQuestionMail(rowData);
                              console.log({ MailSent: MailSent.data.message });
                              if (MailSent.data.message === "Done") {
                                update();
                                message.success("Questions request mail sent...");
                              } else {
                                message.error("Something went wrong...");
                              }
                            }}
                            style={{ color: "green", border: "1px solid green" }}
                          >
                            Request Question Email
                          </Button>
                        ) : (
                          <Button
                            onClick={async () => {
                              console.log({ rowData, data, isCaseLog, customerData, update, UpdateInvoiceItem })
                              let MailSent = await serviceCaller.rejectionQuestionMail(rowData);
                              console.log({ MailSent: MailSent.data.message });
                              if (MailSent.data.message === "Done") {
                                update();
                                message.success("Questions request mail sent...");
                              } else {
                                message.error("Something went wrong...");
                              }
                            }}
                            style={{ color: "red", border: "1px solid red" }}
                          >
                            Request Question Email
                          </Button>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )
            ) : (
              <>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InvoiceItemSubRow;

/*
<Button variant="outlined" color="primary">
  Primary
</Button>
<Button variant="outlined" color="secondary">
  Secondary
</Button>

*/
