import * as actionTypes from "actions";

const initialState = {
  masterProducts: [],
  selectedMasterProduct: {},
  selectedMaster: {},
  categories: [],
  coaList: [],
};

const masterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MASTER_PRODUCTS: {
      return Object.assign({}, state, {
        masterProducts: action.payload,
      });
    }

    case actionTypes.DISABLE_MASTER: {
      return Object.assign({}, state, {
        masterProducts: state.masterProducts.map((a) =>
          a._id == action.payload._id ? action.payload : a
        ),
      });
    }

    case actionTypes.SET_MASTER_PRODUCT: {
      return Object.assign({}, state, {
        selectedMaster: action.payload,
      });
    }

    case actionTypes.SELECT_MASTER_PRODUCT: {
      return Object.assign({}, state, {
        selectedMasterProduct: action.payload,
      });
    }

    case actionTypes.GET_CATEGORIES: {
      return Object.assign({}, state, {
        categories: action.payload,
      });
    }

    case actionTypes.ADD_CATEGORY: {
      return Object.assign({}, state, {
        categories: [action.payload, ...state.categories],
      });
    }

    case actionTypes.GET_COA_LIST: {
      return Object.assign({}, state, {
        coaList: action.payload,
      });
    }

    default: {
      return state;
    }
  }
};

export default masterReducer;
