import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";
// import { useSnackbar } from "notistack";
import { notification } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as serviceCaller from "../../service";
import { duration } from "moment";

function ViewInvoiceButton({ invoiceId, supplierId, marketPlaceId, ...rest }) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);

  useEffect(() => {
    if (!invoiceId || !supplierId || !marketPlaceId) {
      setError(true);
    }
  });

  return (
    <Button
      variant="outlined"
      startIcon={
        loading ? (
          <CircularProgress size={18} />
        ) : (
          <Visibility fontSize="small" />
        )
      }
      onClick={async () => {
        setLoading(true);

        try {
          let invoice = await serviceCaller.getInvoiceById(invoiceId);
          let key=invoice.data.result.InvoiceViewCred?.key;
          console.log({key:key});
          if (key) {
            const file = await serviceCaller.getinvoice(key);
            console.log({file});
            console.log("file : ", file)
            const arrayBuffer = new Uint8Array(
              file.response.Body.data
            );
            console.log({ arrayBuffer });
            const blob = new Blob([arrayBuffer], {
              type: "application/pdf",
            });
            console.log({ blob });
            const url = window.URL.createObjectURL(blob);
            
            window.open(
              `${window.location.origin}/doc/${encodeURIComponent(
                url
                )}?wufoo=${invoice.data.result.wufoo}&invoiceNo=${`${invoice.data.result.invoiceData.invoiceNo} - New`}&category=&usedCount=`
            );
          } else {
            console.log("invoice : ", invoice);
            let invoiceGenerator =
              await serviceCaller.getInvoiceGeneratorBySupplierIdAndMarketplaceId(
                supplierId,
                marketPlaceId
              );
              console.log("invoiceGenerator : ", invoiceGenerator);
  
            const doc = new jsPDF("p", "mm", "letter");
            console.log("doc: ", doc);
  
            const invoiceItemSize = Number(
              invoiceGenerator.data.result.invoiceGenerator.invoiceItemsSize
            );
            console.log("invoiceItemSize : ", invoiceItemSize);
  
            function numberWithCommas(x) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
  
            invoice.data.result.invoiceItems =
              invoice.data.result.invoiceItems.map((e, i) => ({
                ...e,
                no: i + 1,
              }));
  
            const invoiceData = [
              {
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                address5: "",
                address6: "",
                ...invoice.data.result.customerData,
              },
              {
                ctId: "",
                term: "",
                termNo: "",
                trackingNo: "",
                poNo: "",
                packageNo: "",
                invoiceNo: "",
                invoiceDate: "",
                salesRep: "",
                ...invoice.data.result.invoiceData,
              },
              [
                ...invoice.data.result.invoiceItems,
                ...Array.from({
                  length:
                    invoiceItemSize - invoice.data.result.invoiceItems.length + 1,
                }).fill({
                  no: "",
                  itemCode: "",
                  description: "",
                  quantity: "",
                  price: "",
                  total: "",
                }),
              ],
              invoice.data.result.invoiceFigure,
            ];
  
            //Invoice preview function using JsPDF function
            let Functionjs = await serviceCaller.functionData(supplierId,marketPlaceId);
            const invoicePreviewFunc = eval(
              Functionjs.data.toString()
            );
            console.log("invoicePreviewFunc : ", invoicePreviewFunc);
            let invoiceBlobUrl = invoicePreviewFunc(
              invoiceData,
              doc,
              numberWithCommas
            );
  
            window.open(
              `${window.location.origin}/doc/${encodeURIComponent(
                invoiceBlobUrl
              )}?wufoo=${invoice.data.result.wufoo}&invoiceNo=${
                invoice.data.result.invoiceData.invoiceNo
              }&category=&usedCount=`
            );
          }
        } catch (err) {
          notification.error({
            message: "Something went wrong!" + JSON.stringify(err),
            placement: "bottomLeft",
            duration: 2.5,
          });
          // enqueueSnackbar("Something went wrong!" + JSON.stringify(err), {
          //   variant: "error",
          // });
        }

        setLoading(false);
      }}
      disabled={loading || error}
      {...rest}
    >
      Invoice
    </Button>
  );
}

export default ViewInvoiceButton;
