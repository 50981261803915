export const GET_MASTER_PRODUCTS = "GET_MASTER_PRODUCTS";
export const SELECT_MASTER_PRODUCT = "SELECT_MASTER_PRODUCT";
export const SET_MASTER_PRODUCT = "SET_MASTER_PRODUCT";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const GET_COA_LIST = "GET_COA_LIST";
export const DISABLE_MASTER = "DISABLE_MASTER";

export const getMasterProducts = (payload) => {
  console.log("getMasterProducts payload : ",payload);
  return {
    type: GET_MASTER_PRODUCTS,
    payload: payload,
  };
};
export const disableMaster = (payload) => {
  return {
    type: DISABLE_MASTER,
    payload: payload,
  };
};

export const setMasterProduct = (payload) => {
  return {
    type: SET_MASTER_PRODUCT,
    payload: payload,
  };
};

export const selectMasterProduct = (payload) => {
  console.log("master actions selectMasterProduct payload",payload);
  return {
    type: SELECT_MASTER_PRODUCT,
    payload: payload,
  };
};

export const addCategory = (payload) => {
  return {
    type: ADD_CATEGORY,
    payload: payload,
  };
};

export const getCategories = (payload) => {
    console.log("masterActions getCategories payload",payload);
  return {
    type: GET_CATEGORIES,
    payload: payload,
  };
};

export const getCoaList = (payload) => {
  return {
    type: GET_COA_LIST,
    payload: payload,
  };
};
