import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as serviceCaller from "../../service";

function DownloadInvoiceButton({
  invoiceId,
  supplierId,
  marketPlaceId,
  invoiceDataValues,
  ...rest
}) {
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);

  useEffect(() => {
    if (!invoiceId || !supplierId || !marketPlaceId) {
      setError(true);
    }
  });

  return (
    <Button
      variant="outlined"
      startIcon={
        loading ? <CircularProgress size={18} /> : <GetApp fontSize="small" />
      }
      onClick={async () => {
        setLoading(true);
        if (invoiceDataValues?.StrikedInvoiceCred) {
          await console.log("invoiceDataValues : ",invoiceDataValues);
          await fetch(
            `${process.env.REACT_APP_BASEURL}invoice/download/${invoiceDataValues._id}?` + new URLSearchParams({
              foo: 'value',
              pdfname: invoiceDataValues.StrikedInvoiceCred?.key,
          }),
            {
              method: "GET",
              headers: new Headers({
                Authorization:
                  "Bearer " + localStorage.getItem("inv_v2_token"),
              }),
            }
          )
            .then((res) => res.blob())
            .then(async(blob) => {
              await console.log("invoiceDataValues");
              let invoiceBlobUrl = await window.URL.createObjectURL(blob);
              console.log(invoiceBlobUrl, "RESPONSE")
              window.open(invoiceBlobUrl);
              setLoading(false);
              // doc.setHeight('100%')

            });
            await console.log("End");
        } else {
          let invoice = await serviceCaller.getInvoiceById(invoiceId);

          console.log({key:invoice.data.result.InvoiceCred});
          if (invoice?.data?.result?.InvoiceCred?.key) {
            await fetch(
              `${process.env.REACT_APP_BASEURL}invoice/pdf/download/${invoice.data.result._id}?` + new URLSearchParams({
                foo: 'value',
                pdfname: invoice.data.result.InvoiceCred.key,
              }),
              {
                method: "GET",
                headers: new Headers({
                  Authorization:
                    "Bearer " + localStorage.getItem("inv_v2_token"),
                }),
              }
            )
              .then((res) => res.blob())
              .then(async (blob) => {
                await console.log("invoiceDataValues");
                let invoiceBlobUrl = await window.URL.createObjectURL(blob);
                console.log(invoiceBlobUrl, "RESPONSE")
                window.open(invoiceBlobUrl);
                setLoading(false);
                // doc.setHeight('100%')

              });
          } else {
            let invoiceGenerator =
              await serviceCaller.getInvoiceGeneratorBySupplierIdAndMarketplaceId(
                supplierId,
                marketPlaceId
              );
  
            const doc = new jsPDF("p", "mm", "letter");
  
            const invoiceItemSize = Number(
              invoiceGenerator.data.result.invoiceGenerator.invoiceItemsSize
            );
  
            function numberWithCommas(x) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
  
            invoice.data.result.invoiceItems = invoice.data.result.invoiceItems.map(
              (e, i) => ({ ...e, no: i + 1 })
            );
  
            const invoiceData = [
              {
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                address5: "",
                address6: "",
                ...invoice.data.result.customerData,
              },
              {
                ctId: "",
                term: "",
                termNo: "",
                trackingNo: "",
                poNo: "",
                packageNo: "",
                invoiceNo: "",
                invoiceDate: "",
                salesRep: "",
                ...invoice.data.result.invoiceData,
              },
              [
                ...invoice.data.result.invoiceItems,
                ...Array.from({
                  length:
                    invoiceItemSize - invoice.data.result.invoiceItems.length + 1,
                }).fill({
                  no: "",
                  itemCode: "",
                  description: "",
                  quantity: "",
                  total: "",
                }),
              ],
              invoice.data.result.invoiceFigure,
            ];
  
            //Invoice preview function using JsPDF function
  
            let Functionjs = await serviceCaller.functionData(supplierId,marketPlaceId);
  
            const invoicePreviewFunc = eval(
              Functionjs.data.toString()
            );
            invoicePreviewFunc(invoiceData, doc, numberWithCommas);
  
            let base64data;
            let reader = new FileReader();
            console.log("re", reader);
            reader.onloadend = () => {
              base64data = reader.result;
              console.log("b64", base64data);
              fetch(`${process.env.REACT_APP_BASEURL}invoice/convert`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("inv_v2_token"),
                },
                body: JSON.stringify({
                  string: base64data,
                  invoiceData: invoiceDataValues,
                }),
              }).then((res) => {
                fetch(
                  `${process.env.REACT_APP_BASEURL}invoice/convert?fileName=${invoiceData[1].invoiceNo}_${invoiceData[0].address1}`,
                  {
                    method: "GET",
                    headers: new Headers({
                      Authorization:
                        "Bearer " + localStorage.getItem("inv_v2_token"),
                    }),
                  }
                )
                  .then((res) => res.blob())
                  .then((blob) => {
                    let invoiceBlobUrl = window.URL.createObjectURL(blob);
                    console.log(invoiceBlobUrl, "RESPONSE")
                    window.open(invoiceBlobUrl);
                    setLoading(false);
                    // doc.setHeight('100%')
  
                  });
              });
            };
            reader.readAsDataURL(doc.output("blob"));
          }
        }
      }}
      disabled={loading || error}
      {...rest}
    >
      Invoice
    </Button>
  );
}

export default DownloadInvoiceButton;
