import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, IconButton } from "@mui/material";
import { useMutation } from "react-query";
import axios from "axios";
// import { useSnackbar } from "notistack";

import * as serviceCaller from "../../service";
import { notification } from "antd";

function DeleteInvoiceButton({ invoiceId }) {
  // let { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let mutation = useMutation(
    (invoiceId) => serviceCaller.deleteNewInvoice(invoiceId),
    {
      onError: (error, variables, context) => {
        // enqueueSnackbar(
        //   error.response && error.response.data && error.response.data.message,
        //   {
        //     variant: "error",
        //   }
        // );
        notification.error(
          error.response && error.response.data && error.response.data.message,
        )
      },
      onSuccess: (data, variables, context) => {
         notification.success({
                    message: data.data.message
                  });
        // enqueueSnackbar(data.data.message, {
        //   variant: "success",
        // });
      },
    }
  );

  return (
    <>
      <IconButton
        aria-label="delete"
        onClick={() => {
          mutation.mutate(invoiceId);
        }}
        size="small"
      >
        {mutation.isLoading ? (
          <CircularProgress size={18} />
        ) : (
          <DeleteIcon variant="outlined" color="error" size="small" />
        )}
      </IconButton>
    </>
  );
}
export default DeleteInvoiceButton;
