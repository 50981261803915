import { async } from "validate.js";
const axios = require("axios");
const keycloak = require("../keycloak");
const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;
const baseURLV3 = process.env.REACT_APP_ECOMMERCE_API_BASE_URL;

export function getSupplierList(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["supplierName"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}suppliers`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function getAllSuppliers(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}supplier`, {
      headers: headers,
    })
    .then((res) =>
      res.status === 200 ? callback(res.data.data) : callback([])
    )
    .catch(function (error) {});
}

export function getMarketplace(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .get(`${baseURL}supplier/marketplace/${id}`, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

export function getAllMarketPlaces(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}marketplaces`, {
      headers: headers,
    })
    .then((res) =>
      res.status === 200 ? callback(res.data.data) : callback([])
    )
    .catch(function (error) {});
}


export function getSupplierAllMarketPlaces() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return  axios
    .get(`${baseURL}marketplaces`, {
      headers: headers,
    })
    .then((res) =>
      (res.data) 
    )
    .catch(function (error) {});
}



export function addSupplierProduct(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios

    .post(`${baseURL}supplier/${data.supplierId}/addproduct`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

// edit supplier category images

export function editSupplierImage(id, data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .post(`${baseURLV3}categories/${id}/image`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

export function editSupplierBrandImage(brandId, data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .post(`${baseURLV3}brands/${brandId}/image`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// edit supplier category

export function editSupplierCategory(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURLV3}categories/${data.id}`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}
export function editSupplierBrands(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURLV3}brands/${data.id}`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// supplier get category

export function getCategory(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV3}categories`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}


export function getBrands(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV3}brands`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}
// supplier add new category

export function addNewCategory(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURLV3}categories`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

export function addNewBrands(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURLV3}brands`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}
// Supplier Add new Invoices

export function addNewSupplierInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURLV2}suppliers`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// Supplier Add invoice Counter

export function addNewSupplierCounter(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURLV2}counters`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => err);
}

// Supplier invoice creation

export function addSupplierInvoiceCreation(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURLV2}invoicegenerators`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => err);
}

// update counter by supplierId
export function updateCounterBySupplierId(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURLV2}suppliers/${data.supplierId}/counters`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}
// update invoice generator by supplierId and marketplace id

export function updateInvoiceGeneratorBySupplierId(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .put(
      `${baseURLV2}suppliers/${data.supplierId}/marketplaces/${data.marketPlaceId}/invoicegenerators`,
      data,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch((err) => {});
}

export function updateInvoiceGeneratorFunctionBySupplierId(
  { marketPlaceId, supplierId },
  data
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.put(
    `${baseURLV2}suppliers/${supplierId}/marketplaces/${marketPlaceId}/invoicegenerators/function`,
    data,
    {
      headers: headers,
    }
  );
}

// disable supplier invoice

export function disableSupplierReference(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .patch(`${baseURLV2}suppliers/${data._id}`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// get supplier invoice market place

export function getSupplierMarketPlace(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .get(`${baseURLV2}suppliers/${data}`, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// get supplier invoice market place

export function getWufooToDo(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .get(`${baseURL}wufoo-api/getkanban/Todo`, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

export async function functionData(supplierId,marketPlaceId) {
  try {
    return axios
      .get(`https://dufmprd8q0tv0.cloudfront.net/${supplierId}/${marketPlaceId}/invoicegenarator.txt`, {})
      .then((res) => res)
      .catch((err) => {});
  } catch (error) {
    console.log({catchError1:error});
  }
}

// get supplier invoice market place

export async function getSupplierData(category,brand,KanbanTicket,SelectedMarketPlace) {
  try {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.default.token,
    };
    return await axios.get(`${baseURL}wufoo-api/getSupplier`, {
        headers: headers,
        params: {
          category:category && category.length > 0 ? category : [],
          brand:brand && brand.length > 0 ? brand : [],
          KanbanTicket:KanbanTicket,
          SelectedMarketPlace:SelectedMarketPlace,
        },
      })
      .then((res) => res)
      .catch((err) => {
        console.log({err});
      });
  } catch (error) {
    console.log({error});
  }
}
// Supplier marketPlace wise invoice generator

export function marketPlaceInvoiveGenerator(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .get(
      `${baseURLV2}suppliers/${data.supplierId}/marketplaces/${data.marketPlaceId}/invoicegenerators`,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch((err) => {});
}

//  get Counter by supplier Id

export function getCounterBySupplierId(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .get(`${baseURLV2}suppliers/${data.supplierId}/counters`, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

export function getInvoiceGeneratorBySupplierIdAndMarketplaceId(
  supplierId,
  marketPlaceId
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios.get(
    `${baseURLV2}suppliers/${supplierId}/marketplaces/${marketPlaceId}/invoicegenerators`,
    {
      headers: headers,
    }
  );
}

export function UpdateInprogressWufoo(data) {
  let headers = {
    "Content-Type": "application/javascript",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.put(`${baseURL}wufoo-api/inprogress/${data}`, data, {
    headers: headers,
  });
}
export function editSupplierProduct(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURL}supplier/${data.supplierId}/updateproduct`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function addSupplierProductToWebsite(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .patch(`${baseURL}supplier/${data.supplierId}/updateproduct`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => err);
}

export function getSupplierProducts(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // return axios
  //   .get(
  //     `${baseURL}supplier/${data.supplierId}/search?searchTerm=${data.searchTerm}&searchBy=${data.searchBy}&marketPlace=${data.marketPlace}&pageNo=${data.page}&pageSize=${data.pagesize}`,
  //     {
  //       headers: headers,
  //     }
  //   )
  //   .then((res) => {
  //     res.status === 200 ? callback(res.data.data) : callback([]);
  //   })
  //   .catch(function (error) {});

  return axios
    .get(
      `${baseURL}supplier/${data.supplierId}/search`,
      {
        headers: headers,
      
        params: {
          searchproductName: data.productName,
          searchBy: data.searchBy,
          marketPlace: data.marketPlace,
          pageNo: data.page,
          pageSize: data.pagesize,
          searchAsin: data.asin,
          searchCategory: data.category,
          searchPrice: data.price,
          searchProductCode: data.productCode,
          sortBy:data.sort,
          searchType:data.type
        }, 
      },

    )
    .then(
      (res) => res.data
      // console.log("getSupplierProducts -- service caller ",res);
      
    ).catch(function (error) {});

  }

  export function getMarketplacedetails(id) {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.default.token,
    };
  
    return axios
      .get(
        `${baseURL}marketplaces/${id}`,
        {
          headers: headers,
        }
      )
      .then((res) => res.data)
      .catch(function (error) {});
  }

  export function getSupplierProduct(data) {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.default.token,
    };
  
    return axios
      .get(
        `${baseURL}supplier/${data.supplierId}/search?searchTerm=${data.searchTerm}&searchBy=${data.searchBy}&marketPlace=${data.marketPlace}&prediction=${data.prediction}&asins=${data.asins}&category=${data.category}`,
        {
          headers: headers,
        }
      )
      .then((res) => res.data)
      .catch(function (error) {});
  }

export function getInvoiceGenerator(data, callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}supplier/${data.supplierId}/invoicegenerator`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data.data);
        return res.data.data;
      } else {
        callback([]);
        return true;
      }
    })
    .catch(function (error) {
      return true;
    });
}

// Product website
export function productWebsite(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["name"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV3}products`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

// Update product website
export function updateProduct(id, data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURLV3}products/${id}`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// add to website product images

export function uploadProductImage(id, data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURLV3}products/${id}/image`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch((err) => {});
}

// add to website product images

export function getSupplier(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({keycloak:keycloak.default.token})
  return axios
    .get(`${baseURL}supplier/${id}`, {
      headers: headers,
      params: {}
    })
    .then((res) => res)
    .catch((err) => {});
}
export function getInvoiceDetails(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log("data: ", data);

  return axios
    .get(`${baseURLV2}invoices/get-invoice-details`, {
      headers: headers,
      params: {
        data : data,
      }
    })
    .then((res) => res)
    .catch((err) => {});
}

export function getAsinDescription(asin) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log("data: ", asin);

  return axios
    .get(`https://public-api.thilash.com/api/v1/tfg/product/description?asin=${asin}`, {
      headers: headers,
      params: {}
    })
    .then((res) => res)
    .catch((err) => {});
}

export function getAsinImage(asin) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log("data: ", asin);

  return axios
    .get(`https://public-api.thilash.com/api/v1/tfg/product/image?asin=${asin}`, {
      headers: headers,
      params: {}
    })
    .then((res) => res)
    .catch((err) => {});
}