/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import CheckAuth from "utils/checkAuth";
import Preview from "views/Preview";

import {
  INV_V2_ADMIN,
  INV_V2_CASELOG,
  INV_V2_DOCUMENTATION,
  INV_V2_VALIDATION,
  INV_V2_USER
} from "./utils/values";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/supplier" />,
  },
  {
    path: "/preview",
    exact: true,
    component: () => <Preview />,
  },
  // {
  //   path: "/auth",
  //   component: AuthLayout,
  //   routes: [
  //     {
  //       path: "/auth/login",
  //       exact: true,
  //       render: (props) => <Login {...props} />,
  //       component: lazy(() => import("views/Login")),
  //     },
  //     {
  //       component: () => <Redirect to="/errors/error-404" />,
  //     },
  //   ],
  // },
  {
    route: "*",
    component: CheckAuth,
    routes: [
      {
        path: "/supplier-dashboard",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/SupplierSheet/SupplierSheet")),
      },
      {
        path: "/master",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Master")),
      },
      {
        path: "/otherinvoice",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/OtherInvoice")),
      },
      {
        path: "/automotive",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Automotive")),
      },
      {
        path: "/supplier",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/Supplier")),
      },
      {
        path: "/wufoo",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("wufoo/index")),
      },
      {
        path: "/aweber",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_CASELOG],
        component: lazy(() => import("salesforce/index")),
      },
      {
        path: "/users",
        exact: true,
        access: [INV_V2_ADMIN],
        component: lazy(() => import("users/index")),
      },
      {
        path: "/supplier/:supplierId/products",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/SupplierProducts")),
      },
      {
        path: "/supplier/:supplierId/categories",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/SupplierCategories")),
      },
      {
        path: "/supplier/:supplierId/website-products",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION],
        component: lazy(() => import("views/Supplier/AddToWebsite")),
      },
      {
        path: "/supplier/:supplierId/invoice",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/SupplierInvoice")),
      },
      {
        path: "/supplier/:supplierId/invoicenew",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/Container/v3_Invoice/create_invoice")),
        // D:\thilash-063\development\INV-V3-FrontEnd\src\views\Supplier\Container\v3_Invoice\create_invoice.js
      },
      {
        path: "/supplier/:supplierId/invoice/:invoiceId",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/SupplierInvoiceEdit")),
      },
      {
        path: "/supplier/:supplierId/editinvoice",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/SupplierInvoiceEdit")),
      },
      {
        path: "/supplier/:supplierId/invoices",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        component: lazy(() => import("views/Supplier/SupplierInvoices")),
      },
      {
        path: "/validate",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_VALIDATION],
        component: lazy(() => import("views/Validate")),
      },
      {
        path: "/caselog",
        exact: true,
        access: [
          INV_V2_ADMIN,
          INV_V2_CASELOG,
          INV_V2_DOCUMENTATION,
          INV_V2_VALIDATION,
        ],
        component: lazy(() => import("views/CaseLog")),
      },
      {
        path: "/supplier/:supplierId/website-categories",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION],
        component: lazy(() => import("views/Supplier/AddCategories")),
      },
      {
        path: "/supplier/:supplierId/website-brands",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION],
        component: lazy(() => import("views/Supplier/AddBrands")),
      },
      {
        path: "/reports",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_CASELOG,INV_V2_USER],
        component: lazy(() =>
          import("views/ReportGeneration/ReportGeneration")
        ),
      },
      {
        path: "/application",
        exact: true,
        access: [INV_V2_ADMIN, INV_V2_CASELOG],
        component: lazy(() =>
          import("views/ApplicationTracker/ApplicationTracker")
        ),
      },
      {
        path: "/settings",
        exact: true,
        access: [INV_V2_ADMIN],
        component: lazy(() => import("views/Settings/Settings")),
      },
      {
        path: "/files",
        exact: true,
        access: [INV_V2_ADMIN],
        component: lazy(() => import("views/Files")),
      },
      {
        path: "/Wufoo-details",
        exact: true,
        access: [INV_V2_ADMIN],
        component: lazy(() => import("wufoo/wufooDetails")),
      },
      {
        path: "/doc/:url",
        exact: true,
        access: [
          INV_V2_ADMIN,
          INV_V2_DOCUMENTATION,
          INV_V2_VALIDATION,
          INV_V2_CASELOG,
        ],
        component: lazy(() => import("views/Doc/Doc")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
        access: [
          INV_V2_ADMIN,
          INV_V2_CASELOG,
          INV_V2_DOCUMENTATION,
          INV_V2_VALIDATION,
        ],
      },
    ],
  },
];


export default routes;
