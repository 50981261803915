import React, { Fragment, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@mui/material";
import { Hidden } from "@mui/material";

import LogoImage from "../../../../images/aweber.png";
import useRouter from "utils/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";
// import { FaSalesforce } from "@react-icons/all-files/fa/FaBeer";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import InputIcon from "@mui/icons-material/Input";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import styled from 'styled-components';

import { useKeycloak } from "@react-keycloak/web";
import { createTheme, ThemeProvider  } from '@mui/material/styles';
const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 50,
    padding: 5,
  },
  navIcon: {
    color: "#E6E6E6",
    fontSize: 15,
  },
  navItems: {
    color: "#E6E6E6",
    paddingTop: 10,
    opacity: 0.5,
    transition: "0.2s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      color: "#E6E6E6",
      opacity: 1,
    },
    "&:active": {
      color: "#E6E6E6",
      opacity: 1,
      transform: "scale(1.1)",
    },
  },
  navText: {
    fontSize: 11,
    color: "#E6E6E6",
  },
}));

const Wrapper = styled.div`

`;

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  const [keycloak, initialized] = useKeycloak();

  const isAllowed = (realmRoles, pageRoles) => {
    let matchingRoles = pageRoles.filter(function (e) {
      return realmRoles.indexOf(e) > -1;
    });
    return matchingRoles.length >= 1 ? true : false;
  };

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  return (
   
<ThemeProvider theme={theme}>

  <Wrapper>
     <Fragment>
       <div>
         <RouterLink to="/">
           <img
             alt="Logo"
             className={classes.logo}
             src="/images/logos/TFG.png"
           />
         </RouterLink>
         {navigationConfig.map((list) => {
           // if(isAllowed(keycloak.realmAccess.roles, ))
           let allowedPages = list.pages.filter((e) =>
             isAllowed(keycloak.realmAccess.roles, e.access)
           );
           return (
             <RouterLink to={"/aweber"}>
               <div className={classes.navItems}>
                 <div>
                   <img 
                     alt="Logo"
                     style={{width:"50%"}}
                     className={classes.logo} 
                     src={LogoImage}/>
                 </div>
                 <p className={classes.navText}>{"Aweber"}</p>
               </div>
             </RouterLink>)
         })}
       </div>
       <div>
         {/* <RouterLink to="/">
         <img
           alt="Logo"
           className={classes.logo}
           src="/images/logos/TFG.png"
         />
       </RouterLink> */}
         {navigationConfig.map((list) => {
           // if(isAllowed(keycloak.realmAccess.roles, ))
           let allowedPages = list.pages.filter((e) =>
             isAllowed(keycloak.realmAccess.roles, e.access)
           );
           return allowedPages.map(({ title, href, Icon }) => (
             <RouterLink to={href}>
               <div className={classes.navItems}>
                 <Icon className={classes.navIcon} />
                 <p className={classes.navText}>{title}</p>
               </div>
             </RouterLink>
           ));
         })}
       </div>
       <div className={classes.navItems} onClick={() => keycloak.logout()}>
         <InputIcon
           className={classes.navIcon}
           onClick={() => keycloak.logout()}
         />
         <p className={classes.navText}>Logout</p>
       </div>
     </Fragment>
   </Wrapper>
 
</ThemeProvider>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loggedInUser: state.session.user,
});

export default connect(mapStateToProps)(NavBar);
