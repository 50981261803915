const axios = require("axios");
const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;

const { INVOICE_BOX, INVOICE_RANDOM } = require("../utils/values");

export function getApprovedList(
  filter = {},
  datefilter={},
  page = 1,
  pageSize = 10,
  sort = "_id"
) {
  console.log({sort});
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}approvedlists`, {
    headers: headers,
    params: {
      filter:filter,
      datefilter:datefilter,
      page,
      pageSize,
      sort,
    },
  });
}

export async function syncApprovedList() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.get(`${baseURLV2}approvedlists/sync`, {
    headers: headers,
    params: {},
  });
}

export function addApprovedList(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURLV2}approvedlists`, data, { headers: headers });
}
