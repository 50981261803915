import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import {
    Button,
    Tag,
    Tooltip,
    Modal,
    Input,
    Form,
    Grid,
    Col,
    Row,
    notification,
} from 'antd';
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";
import {
    INVOICE_AUTOMOTIVE,
    INVOICE_RANDOM,
    INVOICE_BOX,
    INVOICE_GENERAL,
} from "../../utils/values";
import { async } from 'validate.js';
const { TextArea } = Input;

function AppealSendEmailAction({ invoiceData, invoiceItemData, customerData, update }) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [appealemailData, setAppealEmailData] = useState();

    const appealData = async () => {
        let res = await serviceCaller.getrejectCaseAppealEmail({ invoiceItemData });
        console.log("res : ", res);
        if (res.data.message === "Appeal Email-List is Retrieved") {
            setAppealEmailData(res.data.appealSend.EmailContentTwo);
        }
    }
    
    let BodyConetent =
        `   Applied case got rejected, It's an Auto Rejection, you need to defend the case on seller central. 
   1. Please log in using the primary seller account email address (${customerData?.sellerEmail}).
   2. Navigate to "Case Log" Page by clicking "Help" on the home page.
   3. If it shows "Answered" -> click "View" -> click "Get Help" -> "I need more help with this issue" and then paste the below "Red Colored text"
  `;
    let DefendConentAppeal = `Team, Looks like case got rejected by mistake. can you please review it once again and approve the same`;
    if (appealemailData) {
        form.setFieldsValue({
            DefendMessage: appealemailData,
        })
    } else {
        form.setFieldsValue({
            DefendMessage: DefendConentAppeal,
        })

    }
    useEffect(() => {
        form.setFieldsValue({
            WufooNumber: invoiceData?.wufoo ?? null,
            To: customerData?.communicationEmail ?? null,
            Subject: `Amazon Case Application Update - Auto Rejection - ${invoiceData?.wufoo}`,
            Body: BodyConetent,
        })
    }, []);

    return (
        <div>
            <>
                {invoiceItemData.EmailStatus === "AppealEmailSent" ? (
                    <>
                        <Button
                            // type='primary'
                            ghost
                            onClick={() => {
                                setIsModalOpen(true);
                                appealData();
                            }}
                            style={{ color: "green", backgroundColor: "lightgreen", border: "1px solid green" }}
                        >
                            APPEAL
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type='primary'
                            ghost
                            onClick={() => {
                                setIsModalOpen(true);
                                appealData();
                            }}
                        >
                            APPEAL
                        </Button>
                    </>

                )}
                <>
                    <Modal
                        title="APPEAL"
                        open={isModalOpen}
                        onCancel={() => {
                            setIsModalOpen(false);
                        }}
                        width={650}
                        footer={null}
                    >
                        <Form
                            onFinish={async (value) => {
                                console.log("value ", value);
                                let res = await serviceCaller.rejectCaseAppealEmail({ value, invoiceData, invoiceItemData });
                                console.log("res ", res);
                                // setAppealEmailSent(res.data.appealSend.EmailStatus);
                                if (res.status == 200) {
                                    console.log("LETS TFG : 8 ");
                                    update();
                                    notification.success({
                                        message: res.data.message,
                                    })
                                }
                                setIsModalOpen(false);
                            }}
                            form={form}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='WufooNumber'
                                        label="Wufoo Number"
                                    >
                                        <Input style={{ border: "none", pointerEvents: "none" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='To'
                                        label="To"
                                    >
                                        <Input style={{ border: "none", pointerEvents: "none" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='Subject'
                                        label="Subject"
                                    >
                                        <Input style={{ border: "none", pointerEvents: "none", fontWeight: "bold", }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='Body'
                                        label="Body"
                                        labelCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'BodyContent Required',
                                            },
                                            {
                                                whitespace: true,
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            style={{ height: "100px" }}
                                            defaultValue={BodyConetent.toString()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='DefendMessage'
                                        label="Defend Message"
                                        labelCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'DefendMessage required',
                                            },
                                            {
                                                whitespace: true,
                                            }
                                        ]}
                                    >
                                        <TextArea style={{ fontWeight: "bold" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row >
                                <Col span={24} >
                                    <div style={{ marginLeft: "540px", }}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType='submit'
                                            >
                                                Send
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </>
            </>
        </div>
    );
}

export default AppealSendEmailAction;


