const axios = require("axios");
const keycloak = require("../keycloak");
const baseURL = process.env.REACT_APP_BASEURL;

export async function getWufooCustomer(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.get(`${baseURL}wufoo-api/get-customer`, {
    headers: headers,
    params: {
      filter:data.filter,
      page:data.page,
      page_size:data.page_size,
      sort:data.sort,
      Searchdata:data.Searchdata,
      isSortCommunicationEmail:data.isSortCommunicationEmail,
      isSortaccountMail:data.isSortaccountMail,
      isSortwufoo:data.isSortwufoo,
      isSortsellerAccount:data.isSortsellerAccount,
      selectVal:data.selectVal,

    },
  });
}

export async function getWufoo(data) {
  let headers = {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin' : '*',
    Authorization: "Bearer " + keycloak.default.token,
  };
  let value={
    accountMail:data.accountMail,
  }
  return await axios.get(`${baseURL}wufoo-api/getWufoo`, {
    headers: headers,
    params: {
      // filter:data.filter,
      ...value,
      // page_size:data.page_size,
      // sort:data.page,
    },
  });
}

export async function getBrandandCategory(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.get(`${baseURL}wufoo-api/getBrandandCategory`, {
    headers: headers,
    params: {
      ...data,
    },
  });
}

export async function getBrandList(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.get(`${baseURL}wufoo-api/getBrandList`, {
    headers: headers,
    params: {
      brand :  data
    },
  });
}

export async function getOLDInvoiceData(data) {
  try {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.default.token,
    };
  
    return await axios.get(`${baseURL}wufoo-api/last/submitted/invoice`, {
      headers: headers,
      params: {
        ...data,
      },
    });
  } catch (error) {
    console.log({error});
  }
}


export async function getAddress(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.get(`${baseURL}wufoo-api/getAddress`, {
    headers: headers,
    params: {
      ...data,
    },
  });
}

// export async function getSupplierData(category,brand) {
//   let headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + keycloak.default.token,
//   };
//   let data={
//     category:category,
//     brand:brand,
//   }
//   return await axios.get(`${baseURL}wufoo-api/getSupplier`, {
//     headers: headers,
//     params: {
//       ...data,
//     },
//   });
// }
export async function GetKanbanUsers(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.get(`${baseURL}wufoo-api/getkanbanUsers`,{
    headers: headers,
    params: {
    },
  });
}
export async function getWufooRecurringCustomer(value) {
  try {
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.default.token,
      };
    
      return axios.get(`${baseURL}wufoo-api/customerData`, {
        headers: headers,
        params: {
          search:value
        },
      });
  } catch (error) {
      console.log({error});
  }
}

export async function getWufooRecurringCustomerTFG(value) {
  try {
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.default.token,
      };
    
      return axios.get(`${baseURL}wufoo-api/recurring/customer/data`, {
        headers: headers,
        params: {
          search:value
        },
      });
  } catch (error) {
      console.log({error});
  }
}

export async function UpdateForm(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.put(`${baseURL}wufoo-api/updateForm/${data._id}`, data, {
    headers: headers,
  });
}

export async function UpdateComments(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.put(`${baseURL}wufoo-api/update_entry/${data._id}`, data, {
    headers: headers,
  });
}

export async function DeleteWufooEntry(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.put(`${baseURL}wufoo-api/deletewufoo/${data._id}`, {}, {
    headers: headers,
  });
}
export async function RevertApproval(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.put(`${baseURL}wufoo-api/revert/approval/${data._id}`, {}, {
    headers: headers,
  });
}

export async function RejectForm(data,formData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  console.log({data});
  return axios.put(`${baseURL}wufoo-api/rejectForm/${formData._id}`, data, {
    headers: headers,
  });
}

// export async function getWufooRecurringCustomer(value) {
//   try {
//       let headers = {
//         "Content-Type": "application/json",
//       };
    
//       return axios.get(`${baseURL}wufoo-api/customerData`, {
//         headers: headers,
//         params: {
//           search:value
//         },
//       });
//   } catch (error) {
//       console.log({error});
//   }
// }


export async function postForm(value) {
  try {
      let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + keycloak.default.token,
      };
      console.log({baseURL});
      return await axios.post(`${baseURL}wufoo-api/form`, value, { headers: headers });
  } catch (error) {
      console.log({error});
  }
}

export async function sendMailforRequestSS(value) {
  try {
      let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + keycloak.default.token,
      };
      console.log({baseURL});
      return await axios.post(`${baseURL}wufoo-api/send-mail/request_screen_shot`, value, { headers: headers });
  } catch (error) {
      console.log({error});
  }
}
export async function rejectionQuestionMail(value) {
  try {
      let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + keycloak.default.token,
      };
      console.log({baseURL});
      return await axios.post(`${baseURL}wufoo-api/send-mail/rejection-question-mail`, value, { headers: headers });
  } catch (error) {
      console.log({error});
  }
}
export async function UpdateForm1(id,data) {
  try {
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.default.token,
      };
      console.log({data});
      return axios.put(`${baseURL}wufoo-api/updatewufoo/${id}`, data, {
        headers: headers,
      });
  } catch (error) {
      console.log({error});
  }
}

export async function getForm(value) {
  try {
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.default.token,
      };
    
      return axios.get(`${baseURL}wufoo-api/get-form`, {
        headers: headers,
        params: {
          id:value
        },
      });
  } catch (error) {
      console.log({error});
  }
}

export async function getSelectedForm(value) {
  try {
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.default.token,
      };
    
      return axios.get(`${baseURL}wufoo-api/get-selected-form`, {
        headers: headers,
        params: {
          id:value
        },
      });
  } catch (error) {
      console.log({error});
  }
}
// export async function getWufooRecurringCustomer(value) {
//     try {
//         let headers = {
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + keycloak.default.token,
//         };
    
//         return axios.get(`${baseURL}wufoo-api/recurring/customer/data`, {
//           headers: headers,
//           params: {
//             search:value
//           },
//         });
//     } catch (error) {
//         console.log({error});
//     }
// }

export async function SaveEnteredEmail(value) {
  try {
      let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + keycloak.default.token,
      };
      console.log({baseURL});
      return await axios.post(`${baseURL}tfg/popup/email`, value);
  } catch (error) {
      console.log({error});
  }
}

