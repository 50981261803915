import React, { useState, useEffect } from 'react';
import {
    Button,
    Tag,
    Tooltip,
    Modal,
    Input,
    Form,
    Grid,
    Col,
    Row,
    notification,
} from 'antd';
import * as serviceCaller from "../../service";
import {
    INVOICE_AUTOMOTIVE,
    INVOICE_RANDOM,
    INVOICE_BOX,
    INVOICE_GENERAL,
} from "../../utils/values";
const { TextArea } = Input;

function S2sSendEmailAction({ invoiceData, invoiceItemData, customerData, update }) {
    const [isModalOpenS2s, setIsModalOpens2s] = useState(false);
    const [s2semailData, setS2sEmailData] = useState();
    const [form] = Form.useForm();
    const s2sData = async () => {
        let res = await serviceCaller.getrejectCaseS2sEmail({ invoiceItemData });
        if (res.data.message === "S2s Email-List is Retrieved") {
            setS2sEmailData(res.data.s2sSend.EmailContentTwo);
        }
    }

    let BodyConetent1 =
        `     You need to defend the case on seller central. 
        1) Please log in using the primary seller account email address (${customerData?.sellerEmail}).
        2) Click "Help" -> "Go to Case Log"
        3) Check the recently "Answered/Need Action" Case
        4) If it shows "Need Action" -> click "View"-> click "Reply" and then paste the below "Red Colored text"
        5) If it shows "Answered" ->  click "View" -> click "Get Help" -> "I need more help with this issue"  and then paste the below "Red Colored text". 
      `;

    let DefendConentS2s = `Team, Check with the supplier and they can provide all the details to you`;
    // console.log("appealemailData: ", appealemailData);
    if (s2semailData) {
        form.setFieldsValue({
            DefendMessage: s2semailData,
        })
    } else {
        form.setFieldsValue({
            DefendMessage: DefendConentS2s,
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            WufooNumber: invoiceData?.wufoo ?? null,
            To: customerData?.communicationEmail ?? null,
            Subject: `Amazon Case Application Update - Need Attention - ${invoiceData?.wufoo}`,
            Body: BodyConetent1,
        })
    }, []);

    return (
        <div>
            <>
                {invoiceItemData.EmailStatus === "S2sEmailSent" ? (
                    <>
                        <Button
                            // type='primary'
                            ghost
                            onClick={() => {
                                setIsModalOpens2s(true);
                                s2sData();
                            }}
                            style={{color:"green" ,backgroundColor:"lightgreen",border:"1px solid green"}}
                        >
                            S2S
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type='primary'
                            ghost
                            onClick={() => {
                                setIsModalOpens2s(true);
                                s2sData();
                            }}
                        >
                          S2S
                        </Button>
                    </>

                )}
                <>
                    <Modal
                        title="S2S"
                        open={isModalOpenS2s}
                        onCancel={() => {
                            setIsModalOpens2s(false);
                        }}
                        width={650}
                        footer={null}
                    >
                        <Form
                            onFinish={async (value) => {
                                console.log("value ", value);
                                let res = await serviceCaller.rejectCaseS2sEmail({ value, invoiceData, invoiceItemData });
                                console.log("res ", res);
                                if (res.status == 200) {
                                    update();
                                    notification.success({
                                        message: res.data.message,
                                    })
                                }
                                setIsModalOpens2s(false);
                            }}
                            form={form}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='WufooNumber'
                                        label="Wufoo Number"
                                    >
                                        <Input style={{ border: "none", pointerEvents: "none" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='To'
                                        label="To"
                                    >
                                        <Input style={{ border: "none", pointerEvents: "none" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='Subject'
                                        label="Subject"
                                    >
                                        <Input style={{ border: "none", pointerEvents: "none" ,fontWeight:"bold"}} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='Body'
                                        label="Body"
                                        labelCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'BodyContent Required',
                                            },
                                            {
                                                whitespace: true,
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            style={{ height: "100px" }}
                                            defaultValue={BodyConetent1.toString()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='DefendMessage'
                                        label="Defend Message"
                                        labelCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'DefendMessage Required',
                                            },
                                            {
                                                whitespace: true,
                                            }
                                        ]}
                                    >
                                        <TextArea style={{ fontWeight: "bold" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row >
                                <Col span={24} >
                                    <div style={{ marginLeft: "540px", }}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType='submit'
                                            >
                                                Send
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </>
            </>
        </div>
    );
}

export default S2sSendEmailAction;


