export const GET_OPEN_INVOICES = "GET_OPEN_INVOICES";
export const UPDATE_OPEN_INVOICES = "UPDATE_OPEN_INVOICES";
export const SEARCH_INVOICES = "SEARCH_INVOICES";
export const REMOVE_VALIDATED_INVOICE = "REMOVE_VALIDATED_INVOICE";
export const GET_VALIDATED_INVOICES = "GET_VALIDATED_INVOICES";
export const REMOVE_CLOSED_INVOICE = "REMOVE_CLOSED_INVOICE";
export const GET_CLOSED_INVOICES = "GET_CLOSED_INVOICES";
export const REMOVE_REOPENED_INVOICE = "REMOVE_REOPENED_INVOICE";
export const SELECT_INVOICE = "SELECT_INVOICE";
export const UPDATE_INVOICES = "UPDATE_INVOICES";
export const BOX_INVOICES = "BOX_INVOICES";
export const RANDOM_INVOICES = "RANDOM_INVOICES";
export const INVOICES_TYPE = "INVOICES_TYPE";

export const changeInvoiceType = (formType) => {
  return {
    type: INVOICES_TYPE,
    payload: formType,
  };
};

export const getAllOpenInvoices = (payload) => {
  return {
    type: GET_OPEN_INVOICES,
    payload: payload,
  };
};

export const updateOpenInvoices = (payload) => {
  return {
    type: UPDATE_OPEN_INVOICES,
    payload: payload,
  };
};

export const searchInvoices = (payload) => {
  return {
    type: SEARCH_INVOICES,
    payload: payload,
  };
};

export const removeValidatedInvoice = (payload) => {
  return {
    type: REMOVE_VALIDATED_INVOICE,
    payload: payload,
  };
};

export const getAllValidatedInvoices = (payload) => {
  return {
    type: GET_VALIDATED_INVOICES,
    payload: payload,
  };
};

export const removeClosedInvoice = (payload) => {
  return {
    type: REMOVE_CLOSED_INVOICE,
    payload: payload,
  };
};

export const getAllClosedInvoices = (payload) => {
  return {
    type: GET_CLOSED_INVOICES,
    payload: payload,
  };
};

export const removeReopenedInvoice = (payload) => {
  return {
    type: REMOVE_REOPENED_INVOICE,
    payload: payload,
  };
};

export const selectInvoice = (payload) => {
  return {
    type: SELECT_INVOICE,
    payload: payload,
  };
};

export const updateInvoices = (payload) => {
  return {
    type: UPDATE_INVOICES,
    payload: payload,
  };
};
