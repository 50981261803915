export const GET_AUTOMOTIVE_WEBSITES = "GET_AUTOMOTIVE_WEBSITES";
export const GET_AVAILABLE_AUTOMOTIVE_WEBSITES =
  "GET_AVAILABLE_AUTOMOTIVE_WEBSITES";

export const getAllAutomotiveWebsites = (payload) => {
  return {
    type: GET_AUTOMOTIVE_WEBSITES,
    payload: payload,
  };
};

export const getAllAvailableAutomotiveWebsites = (payload) => {
  return {
    type: GET_AVAILABLE_AUTOMOTIVE_WEBSITES,
    payload: payload,
  };
};
