import React, { useContext, useEffect, useState } from "react";
import { SmileOutlined, InboxOutlined, QuestionCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios, { isCancel, AxiosError } from 'axios';
import * as serviceCaller from "../service/wufoo";
import ScreenShotImage1 from "../images/screen_shot1.png";
import ScreenShotImage2 from "../images/screen_shot2.png";
import ScreenShotImage3 from "../images/screen_shot3.png";
import Attention from "../images/attention.png";
import {
    Select,
    Row,
    Col,
    Steps,
    Checkbox,
    Progress,
    Form,
    Image,
    Button,
    Input,
    message,
    Upload,
    Radio,
    Space,
    Divider,
    notification
} from 'antd';
const baseURL = process.env.REACT_APP_BASEURL;
const { Dragger } = Upload;
const cloudFront = "https://dnapf9vk17vfg.cloudfront.net";

const Wrapper = styled.div`
    .body{
        max-height: 70vh;
        overflow: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 45px;
        border-color: rgba(58, 58, 58, 0.31);
        background-color: rgba(255, 255, 255, 0.81);
        opacity: 1;
        text-align:center;
    }
    .thankyou{
        text-align: center;
        color: rgb(38, 38, 38);
        font-size: 38px;
    }
    .image{
        margin-bottom: 40px;
    }
    .attension{
        max-width: 80%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px;
    }
    .title-1{
        text-align: center;
        font-size: 20px;
        border-color: rgb(228, 59, 44);
        margin-top: 0px;
        margin-bottom: 10px;
        outline: none;
        color:red;
        cursor: default;
    }
    .step-1{
        text-align: center;
        font-size: 24px;
    }
    .step-1-Image{
        text-align:center;
        margin-top: 10px;
        outline: none;
        cursor: default;
        display: block;
    }
    .step-2{
        text-align: center;
        font-size: 24px;
    }
    .step-2-Image{
        text-align:center;
        margin-top: 10px;
        outline: none;
        cursor: default;
        display: block;
    }
    .step-3-Image{
        text-align:center;
        margin-top: 10px;
        outline: none;
        cursor: default;
        display: block;
    }
    .step-3{
        padding:5%;
        text-align: center;
        font-size: 17px;
        color: rgb(228, 59, 44);    
    }
    .step-4, .step-4-note{
        text-align: center;
        font-size: 17px;
        color: rgb(228, 59, 44);    
    }
`;
const GuidePage = () => {
  return (
    <Wrapper>
        <div className="body">
            {/* <div className="thankyou">
                <span><b>Thanks For Your Order!</b></span>
            </div>
            <div className="thankyou">
                <img src={Attention} className="attension"/>
            </div> */}
            <div className="title-1">
                <span><u>Follow The Below Steps To Fill The Form</u></span>
            </div>
            <div className="step-1">
                <span><b>{"Step 1 - Go To Settings -> Account Info->Seller Central Business Address Page [Take Screenshot]"}</b></span>
            </div>
            <div className="image">
                <div className="step-1-Image">
                    <img src={ScreenShotImage1} className="attension" style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}/>
                </div>
            </div>
            <Divider/>
            <div className="step-2">
                <span><b>{"Step 2 - Go To Settings -> Account Info -> Seller Central Legal Entity Page [Take Screenshot]"}</b></span>
            </div>
            <div className="step-2-Image">
                <img src={ScreenShotImage2} className="attension" style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}/>
            </div>
            <div className="step-3-Image">
                <img src={ScreenShotImage3} className="attension" style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}/>
            </div>
            <Divider/>
            <div className="step-3">
                <span>Upload Both Seller Central Business Address Page & Legal Entity Details Page</span>
            </div>
            <div style={{color: "rgb(228, 59, 44)",fontSize:"20px"}}>
                <span><b>****** DO NOT MISS THIS STEP ******</b></span>
            </div>
        </div>
    </Wrapper>
  )
};

export default GuidePage;