const axios = require("axios");
const keycloak = require("../keycloak");

// const baseURL = "https://dev-api-inv.thilash.com/api/v1/";
// const baseURL = "https://invtest.thefunnelguru.com/api/v1/";
// const baseURL = "http://localhost:3003/api/v1/";
const baseURL = process.env.REACT_APP_BASEURL;

export function updateSettings(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURL}settings`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function getSettings(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}settings`, {
      headers: headers,
    })
    .then((res) =>
      res.data.statusCode == 200 ? callback(res.data.data[0]) : null
    )
    .catch(function (error) {});
}

export async function getUsers() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let response=await axios.get(`${baseURL}settings/users`, {
    headers: headers,
  })
  .then((res) => res.data)
  .catch(function (error) {});
  console.log("response : ",response);
  return response;
}

export async function getEmailNotifications(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let response=await axios.get(`${baseURL}settings/get/email/notifications/${id}`, {
    headers: headers,
  })
  .then((res) => res.data)
  .catch(function (error) {});
  console.log("response : ",response);
  return response;
}

export async function getEmailConfigurationData(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let response=await axios.get(`${baseURL}settings/get/mail/configuration`, {
    headers: headers,
  })
  .then((res) => res.data)
  .catch(function (error) {});
  console.log("response : ",response);
  return response;
}

export async function getActiveEmailConfigurationData() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let response=await axios.get(`${baseURL}settings/get/active/mail/configuration`, {
    headers: headers,
  })
  .then((res) => res.data)
  .catch(function (error) {});
  console.log("response : ",response);
  return response;
}
export async function getNotificationsSettings() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let response=await axios.get(`${baseURL}settings/get/notification`, {
    headers: headers,
  })
  .then((res) => res.data)
  .catch(function (error) {});
  console.log("response : ",response);
  return response;
}

export function RemoveNotificationSettings(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .put(`${baseURL}settings/remove/email/notifications/${id}`, 
    {
      keycloak_id : localStorage.getItem("keycloak_id"),
      kc_id :  keycloak?.default?.idTokenParsed?.sub,
    },  
    { 
      headers: headers,
      params: {
        keycloak_id: keycloak?.default?.idTokenParsed?.sub,
      },

    })
    .then((res) => res)
    .catch(function (error) {});
}


export function notificationSettings(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let keycloak_id = localStorage.getItem("keycloak_id");
  let kc_id =  keycloak?.default?.idTokenParsed?.sub;

  return axios
    .put(`${baseURL}settings/notificationemail`, data,  { headers: headers,

      params: {
        keycloak_id : localStorage.getItem("keycloak_id"),
        keycloak_id: keycloak?.default?.idTokenParsed?.sub,
      },

    })
    .then((res) => res)
    .catch(function (error) {});
}

export function emailList(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}settings/emailview`,value,  { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}  

export function SaveNotificationName(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let kc_id =  keycloak?.default?.idTokenParsed?.sub;

  return axios
    .post(`${baseURL}settings/save/notification`,{
      keycloak_id : kc_id,
      data : value,
    },  { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function SaveNotification(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  let kc_id =  keycloak?.default?.idTokenParsed?.sub;

  return axios
    .post(`${baseURL}settings/save/mail/notification`,{
      keycloak_id : kc_id,
      data : value,
    },  { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function SaveCustomerEmailConfiguration(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return axios
    .post(`${baseURL}settings/save/customer/email/configuration`,{
      data : value,
    },  { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function removeEmail(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}settings/emailremove`,value,  { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}
