import * as actionTypes from "actions";

const initialState = {
  searchedInvoices: [],
  openInvoices: [],
  validatedInvoices: [],
  closedInvoices: [],
  selectedInvoice: {},
  invoiceType: "BOX_INVOICES",
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OPEN_INVOICES: {
      return Object.assign({}, state, {
        openInvoices: action.payload.map((el) => ({
          ...el,
        })),
      });
    }

    case actionTypes.UPDATE_OPEN_INVOICES: {
      return Object.assign({}, state, {
        openInvoices: state.openInvoices.map((el) => {
          if (el._id === action.payload._id) {
            return  action.payload;
          } else {
            return el;
          }
        }),
      });
    }

    case actionTypes.SEARCH_INVOICES: {
      return Object.assign({}, state, {
        searchedInvoices: action.payload.map((el) => ({
          ...el,
        })),
      });
    }

    case actionTypes.REMOVE_VALIDATED_INVOICE: {
      return Object.assign({}, state, {
        openInvoices: state.openInvoices.filter(
          (el) => el._id !== action.payload
        ),
      });
    }

    case actionTypes.GET_VALIDATED_INVOICES: {
      return Object.assign({}, state, {
        validatedInvoices: action.payload.map((el) => ({
          ...el,
        })),
      });
    }

    case actionTypes.REMOVE_CLOSED_INVOICE: {
      return Object.assign({}, state, {
        validatedInvoices: state.validatedInvoices.filter(
          (el) => el._id !== action.payload
        ),
        closedInvoices: [
          ...state.validatedInvoices.filter((el) => el._id === action.payload),
          ...state.closedInvoices,
        ],
      });
    }

    case actionTypes.GET_CLOSED_INVOICES: {
      return Object.assign({}, state, {
        closedInvoices: action.payload.map((el) => ({
          ...el,
        })),
      });
    }

    case actionTypes.REMOVE_REOPENED_INVOICE: {
      return Object.assign({}, state, {
        closedInvoices: state.closedInvoices.filter(
          (el) => el._id !== action.payload
        ),
        validatedInvoices: [
          ...state.closedInvoices.filter((el) => el._id === action.payload),
          ...state.validatedInvoices,
        ],
      });
    }

    case actionTypes.SELECT_INVOICE: {
      return Object.assign({}, state, {
        selectedInvoice: action.payload,
      });
    }

    case actionTypes.UPDATE_INVOICES: {
      return Object.assign({}, state, {
        openInvoices: state.openInvoices.map((el) => {
          const [matchedInvoice] = action.payload.filter(
            (inv) => inv._id === el._id
          );

          if (matchedInvoice) return matchedInvoice;
          else return el;
        }),
        validatedInvoices: state.validatedInvoices.map((el) => {
          const [matchedInvoice] = action.payload.filter(
            (inv) => inv._id === el._id
          );

          if (matchedInvoice) return matchedInvoice;
          else return el;
        }),
        closedInvoices: state.closedInvoices.map((el) => {
          const [matchedInvoice] = action.payload.filter(
            (inv) => inv._id === el._id
          );

          if (matchedInvoice) return matchedInvoice;
          else return el;
        }),
      });
    }
    case actionTypes.INVOICES_TYPE:
      return {
        ...state,
        formType: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default invoiceReducer;
