import React from "react";
import { Button } from "@mui/material";
import { notification } from "antd";
// import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";

function NotUsedInvoiceItemAction({
  invoiceData,
  invoiceItemData,
  setInvoiceItemData,
}) {
  // const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      size="small"
      onClick={async () => {
        serviceCaller
          .updateInvoiceItemById(invoiceItemData._id, {
            result: "notUsed",
          })
          .then((res) => {
            if (res.status === 200) {
              notification.success({
                message:res.data.message,
                placement:"bottomLeft",
                duration:2.5
              })
              // enqueueSnackbar(res.data.message, {
              //   variant: "success",
              // });
              setInvoiceItemData({
                ...invoiceItemData,
                result: res.data.result.result,
              });
            } else {
              notification.error({
                message:res.data.message,
                placement:"bottomLeft",
                duration:2.5
              })
              // enqueueSnackbar(res.data.message, {
              //   variant: "error",
              // });
            }
          });
      }}
    >
      NOT USED
    </Button>
  );
}

export default NotUsedInvoiceItemAction;
